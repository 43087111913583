import React from 'react';
import logo from '../assets/images/logo.svg';
import '../assets/styles/App.css';
import { useQuery } from '@apollo/client';
import { HELLO_QUERY } from '../graphql/Queries';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"

function App() {
  const { loading, data, error } = useQuery(HELLO_QUERY);
  return (
    <Router>

      <div className="App">
        <header style={{ display: "flex", flexDirection: "row" }} >
          <nav>
            <ul style={{ display: "flex", flexDirection: "row", gap: "5px", listStyle: "none" }}>
              <li>
                <Link to="/">home</Link>
              </li>
              <li>
                <Link to="/about">about</Link>
              </li>
              <li>
                <Link to="/contact">contact us</Link>
              </li>
            </ul>
          </nav>
        </header>
        <main className="App-header">
          <Switch>
            <Route path="/" exact render={(props) => (
              <div>
                <h1>Home</h1>
                <img src={logo} className="App-logo" alt="logo" />
                {error ? (<pre>{JSON.stringify(error, null, 2)}</pre>) : null}
                {loading ? <p>"Loading..."</p> : (<p>
                  {data.hello}
                </p>)}
              </div>
            )} />
            <Route path="/about" exact render={() => (<h1>about</h1>)} />
            <Route path="/contact" render={(props) => (<h1>contact</h1>)} />

          </Switch>
        </main>
      </div>
    </Router>
  );
}

export default App;
